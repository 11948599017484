<template>
  <div class="d-flex flex-column">
    <v-img
      src="https://images.unsplash.com/photo-1547570135-c4d6810a9ada?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
      alt=""
      max-height="300"
      height="300"
    ></v-img>
    <v-container fluid>
      <v-card flat color="transparent">
        <v-card-title class="text-h5">Giriş</v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <phone-input
                v-model="$v.loginForm.userName.$model"
                v-bind:properties="{
                  inputmode: 'numeric',
                  prefix: '+90',
                  dense: true,
                  hideDetails: 'auto',
                  placeholder: 'Telefon numaranız',
                  height: '40',
                  errorMessages: userNameErrors(),
                }"
                v-bind:options="{
                  humanMask: '(###)-###-##-##',
                  machineMask: '###########',
                  empty: null,
                  applyAfter: false,
                  alphanumeric: true,
                  lowerCase: false,
                }"
              ></phone-input>

              <!-- <v-text-field
                hide-details="auto"
                full-width
                label=""
                placeholder="E-mail"
                dense
                v-model="$v.loginForm.userName.$model"
                :error-messages="userNameErrors()"
              >
                <template #prepend-inner>
                  <v-icon class="mt-1" small left v-text="'fas fa-at'"> </v-icon>
                </template>
              </v-text-field> -->
            </v-col>
            <v-col cols="12">
              <v-text-field
                type="password"
                autocomplete="off"
                hide-details="auto"
                full-width
                label=""
                placeholder="Password"
                dense
                v-model="$v.loginForm.userPassword.$model"
                :error-messages="passwordErrors()"
              >
                <template #prepend-inner>
                  <v-icon class="mt-1" small left v-text="'fas fa-lock'"> </v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col class="text-right">
              <router-link class="text-capitalize ml-2" depressed text :to="{ name: 'forget' }">
                Şifremi unuttum
              </router-link>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn class="text-capitalize rounded-lg" color="primary" block height="45" @click="loginUser"> Giriş </v-btn>
        </v-card-actions>
        <v-card-text class="d-flex justify-center align-center">
          <div class="mr-2">Henüz kayıt olmadıysanız</div>
          <router-link class="text-capitalize" depressed text :to="{ name: 'register' }"> Kayıt olun </router-link>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { LOGIN, LOGOUT } from '@/store/modules/auth.module';
import PhoneInput from '@/components/PhoneInput.vue';

import { validationMixin } from 'vuelidate';
import loginValidations from './loginValidations';

export default {
  name: 'Login',
  mixins: [validationMixin, loginValidations],
  components: { PhoneInput },
  data() {
    return {
      loginForm: {
        userName: null,
        userPassword: null,
      },
    };
  },
  created() {
    this.$nextTick(() => {
      if (this.$route.name === 'logout') {
        this.logout().then(() => {
          this.$router.push({ name: 'login' });
        });
      }
    });
  },
  methods: {
    ...mapActions({
      login: LOGIN,
      logout: LOGOUT,
    }),
    loginUser() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const payload = {
        ...this.loginForm,
        userName: `90${this.loginForm.userName}`,
      };

      this.login(payload).then(() => {
        this.$router.push({ name: 'garage' });
      });
    },
  },
};
</script>

<style></style>
