var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column"},[_c('v-img',{attrs:{"src":"https://images.unsplash.com/photo-1547570135-c4d6810a9ada?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80","alt":"","max-height":"300","height":"300"}}),_c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"flat":"","color":"transparent"}},[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Giriş")]),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('phone-input',{attrs:{"properties":{
                inputmode: 'numeric',
                prefix: '+90',
                dense: true,
                hideDetails: 'auto',
                placeholder: 'Telefon numaranız',
                height: '40',
                errorMessages: _vm.userNameErrors(),
              },"options":{
                humanMask: '(###)-###-##-##',
                machineMask: '###########',
                empty: null,
                applyAfter: false,
                alphanumeric: true,
                lowerCase: false,
              }},model:{value:(_vm.$v.loginForm.userName.$model),callback:function ($$v) {_vm.$set(_vm.$v.loginForm.userName, "$model", $$v)},expression:"$v.loginForm.userName.$model"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"type":"password","autocomplete":"off","hide-details":"auto","full-width":"","label":"","placeholder":"Password","dense":"","error-messages":_vm.passwordErrors()},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{staticClass:"mt-1",attrs:{"small":"","left":""},domProps:{"textContent":_vm._s('fas fa-lock')}})]},proxy:true}]),model:{value:(_vm.$v.loginForm.userPassword.$model),callback:function ($$v) {_vm.$set(_vm.$v.loginForm.userPassword, "$model", $$v)},expression:"$v.loginForm.userPassword.$model"}})],1),_c('v-col',{staticClass:"text-right"},[_c('router-link',{staticClass:"text-capitalize ml-2",attrs:{"depressed":"","text":"","to":{ name: 'forget' }}},[_vm._v(" Şifremi unuttum ")])],1)],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"text-capitalize rounded-lg",attrs:{"color":"primary","block":"","height":"45"},on:{"click":_vm.loginUser}},[_vm._v(" Giriş ")])],1),_c('v-card-text',{staticClass:"d-flex justify-center align-center"},[_c('div',{staticClass:"mr-2"},[_vm._v("Henüz kayıt olmadıysanız")]),_c('router-link',{staticClass:"text-capitalize",attrs:{"depressed":"","text":"","to":{ name: 'register' }}},[_vm._v(" Kayıt olun ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }