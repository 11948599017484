/* eslint-disable no-unused-expressions */
import { required, maxLength, minLength, helpers } from 'vuelidate/lib/validators';
const phoneCheck = helpers.regex('phoneCheck', /^(\d{3})(\d{3})(\d{2})(\d{2})$/);
const loginValidations = {
  validations: {
    loginForm: {
      userName: {
        required,
        phoneCheck,
      },
      userPassword: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(16),
      },
    },
  },
  methods: {
    userNameErrors() {
      const errors = [];
      const { $dirty, required, phoneCheck } = this.$v.loginForm.userName;
      if (!$dirty) return errors;
      !required && errors.push('Bu bilgi gereklidir.');
      !phoneCheck && errors.push(`Lütfen geçerli bir telefon numarası giriniz.`);
      return errors;
    },
    passwordErrors() {
      const errors = [];
      const { $dirty, required, minLength, maxLength, $params } = this.$v.loginForm.userPassword;
      if (!$dirty) return errors;
      !required && errors.push('Lütfen bir şifre giriniz.');
      !minLength && errors.push(`Şifre minimum ${$params.minLength.min} karakter olmalıdır.`);
      !maxLength && errors.push(`Şifre maksimum ${$params.maxLength.max} karakter olabilir.`);
      return errors;
    },
  },
};

export default loginValidations;
